import Loader from "react-loader-spinner";
import { LoaderStyle } from "../Organizations/styled";
import React from "react";

// this element uses height to switch content for a loader
// in the grid layouts, the subgrids "jump" when reerendered.

export function GridElementSkeleton({ loading, children }) {
  return (
    <>
      <LoaderStyle show={loading}>
        <Loader
          type="Triangle"
          color="hsla(170, 45%, 35%, 1)"
          height={100}
          width={100}
          timeout={0} //3 secs
        />
      </LoaderStyle>

      <div
        style={{
          height: loading ? "0px" : "auto",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </>
  );
}
