import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { Grid, LoaderStyle, NewOrg, OrgProjects, OrgUsers } from "./styled";
import { OrgDetails, OrgList } from "./styled";
import { fetchUsers } from "../../redux/users/action";
import { Form } from "../Misc/Form";
import { Button } from "../Misc/Button";
import { routes } from "../../constants/routes";
import axios from "axios";
import { isBrowser, refreshAndCall } from "../../services/auth";
import { UserPlusIcon } from "../../assets/icons/UserPlusIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { NamedField } from "../Misc/namedField";
import { Search } from "../Misc/Search";
import { RoleTag } from "../Misc/RoleTag";
import { UserMinusIcon } from "../../assets/icons/UserMinus";
import colors from "../../constants/colors";
import { StyledLink } from "../Misc/StyledLink";
import Loader from "react-loader-spinner";
import { GridElementSkeleton } from "../Misc/GridElementSkeleton";

export function Organizations({
  path,
  organizations,
  setOrganizationsFetching,
  fetchOrganizations,
  organizationsFetching,
  fetchUsers,
  getUser,
  user,
  users,
}) {
  const initialState = {
    orgName: "",
    search: "",
    newUser: "",
    role: "user",
  };
  const [selectedOrg, setSelectedOrg] = useState(-1);
  const [state, setState] = useState(initialState);
  const [extended, setExtended] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [ofetch, setOfetch] = useState(organizationsFetching);
  const orgUsersRef = useRef();

  const [newProjectName, setNewProjectName] = useState("");

  require("react-loader-spinner/dist/loader/css/react-spinner-loader.css");

  useEffect(() => {
    fetchOrganizations();
    fetchUsers();
  }, []);

  function createOrg(event) {
    event.preventDefault();

    const jwt = user.token.accessToken;

    refreshAndCall(() => {
      return axios.post(
        routes.api.organizations,
        { name: state.orgName },
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
    }).then((r) => {
      setState((prevState) => ({ ...prevState, orgName: "" }));
      setExtended(false);
      fetchOrganizations();
    });
  }

  function onChange(event) {
    event.persist();

    console.log("*********");
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    console.log(state);
  }

  function onSearch(event) {
    search(event.target.value);
    setState((prevState) => ({ ...prevState, search: event.target.value }));
  }

  function search(arg) {
    console.log(arg);
    if (!arg.length) {
      setSearchResults([]);
      return;
    }
    let results = [];
    users.forEach((user) => {
      if (
        user.name
          .toString()
          .toLowerCase()
          .includes(arg.toString().toLowerCase()) ||
        user.email
          .toString()
          .toLowerCase()
          .includes(arg.toString().toLowerCase())
      ) {
        results.push(user);
      }
    });
    setState((prevState) => ({ ...prevState, newUser: "" }));
    setSearchResults(results);
  }

  function clearSearch() {
    setState((prevState) => ({
      ...prevState,
      search: "",
      newUser: "",
      role: "user",
    }));
    setSearchResults([]);
  }

  function addUser() {
    // TODO The loading time can be reduced by displaying the organization already
    // when the add query is successful.
    // Or use the optimistic pattern to display it instantly.
    const jwt = user.token.accessToken;
    setOfetch(true);
    setOrganizationsFetching();
    refreshAndCall(() => {
      return axios.post(
        routes.api.organizationUsers(organizations[selectedOrg].id),
        {
          uid: state.newUser.id,
          role: state.role,
          name: state.newUser.name,
        },
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
    })
      .then((result) => {
        // success

        clearSearch();
        fetchOrganizations();
      })
      .catch((err) => console.error(err));
  }

  function removeUser(uid) {
    const jwt = user.token.accessToken;
    setOfetch(true);
    refreshAndCall(() => {
      return axios.delete(
        routes.api.organizationUser(organizations[selectedOrg].id, uid),
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
    })
      .then((result) => {
        // success
        clearSearch();
        fetchOrganizations();
      })
      .catch((err) => console.error(err));
  }

  return (
    <AppLayout path={path}>
      <Grid>
        <OrgList>
          <h2>Organizations</h2>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                {/*<th>Status</th>*/}
              </tr>
            </thead>
            <tbody>
              {Object.keys(organizations).length
                ? organizations.map((org, index) => {
                    return (
                      <tr
                        key={"org-td-" + index}
                        className={
                          index === selectedOrg ? "tr-body active" : "tr-body"
                        }
                        onClick={() => {
                          clearSearch();
                          setSelectedOrg(index);
                        }}
                      >
                        <td className={"name"}>{org.name}</td>
                        {/*<td>App Live</td>*/}
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </OrgList>

        <OrgUsers show={selectedOrg >= 0}>
          <GridElementSkeleton loading={organizationsFetching}>
            <h2>Mitglieder</h2>
            <ul>
              {organizations[selectedOrg]?.users?.map((orgUser, index) => {
                const user = getUser(orgUser.uid);
                return (
                  <li key={"org-user" + index}>
                    <div className={"name"}>
                      <span className={"name-name"}>{user.name}</span>
                      <span className={"name-email"}>{user.email}</span>
                    </div>
                    <div className={"role"}>
                      <RoleTag role={orgUser.role} />
                    </div>
                    <div className={"delete"}>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              `${user.name} wirklich von ${organizations[selectedOrg].name} entfernen?`
                            )
                          ) {
                            removeUser(user.id);
                          }
                        }}
                      >
                        Entfernen
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>

            <Search>
              <div className={"search-field"}>
                <SearchIcon />
                <input
                  type={"text"}
                  name={"search"}
                  placeholder={"Suche nach Personen"}
                  value={state.search}
                  onChange={onSearch}
                />
              </div>

              <ul className={"search-results"}>
                {searchResults.map((user, index) => {
                  return (
                    <li key={"search-result" + index}>
                      <button
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            newUser: user,
                          }))
                        }
                      >
                        {user.name}, {user.email}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </Search>

            <div
              className={"add-user"}
              style={{ display: state.newUser ? "flex" : "none" }}
            >
              <NamedField>
                <label>Name</label>
                <span>{state.newUser?.name}</span>
              </NamedField>

              <NamedField>
                <label>E-Mail</label>
                <span>{state.newUser?.email}</span>
              </NamedField>
            </div>

            <select
              value={state.role}
              onChange={onChange}
              name={"role"}
              style={{ display: state.newUser ? "flex" : "none" }}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>

            <Button
              className={"primary"}
              style={{ display: state.newUser ? "flex" : "none" }}
              onClick={addUser}
            >
              <UserPlusIcon stroke={"white"} /> {state.newUser?.name} hinzufügen
            </Button>
          </GridElementSkeleton>
        </OrgUsers>

        <OrgDetails show={selectedOrg >= 0}>
          <h2>Details</h2>

          <NamedField className={"field"}>
            <label>Name</label>
            <span>{organizations[selectedOrg]?.name}</span>
          </NamedField>
        </OrgDetails>

        <OrgProjects show={selectedOrg >= 0}>
          <h2>Projects</h2>

          <div className={"project-list"}>
            {organizations[selectedOrg]?.projects.map((project, index) => {
              return (
                <div className={"project"} key={"project-list-item-" + index}>
                  <h3>{project.name}</h3>
                  <span className={"date"}>
                    {new Date(project.createdAt).toLocaleDateString()}
                  </span>
                  {/*TODO: make generic after project generation*/}
                  <StyledLink
                    to={`http://${project.name}.${process.env.PROJECT_DOMAIN}`}
                  >
                    Link zum Projekt
                  </StyledLink>
                </div>
              );
            })}

            <form
              className={"project add-project"}
              onSubmit={(event) => {
                event.preventDefault();

                const jwt = user.token.accessToken;

                refreshAndCall(() => {
                  return axios.post(
                    routes.api.organizationProjects(
                      organizations[selectedOrg]?.id
                    ),
                    { name: newProjectName },
                    {
                      headers: {
                        authorization: `Bearer ${jwt}`,
                      },
                    }
                  );
                }).then((r) => {
                  // TODO update before;
                  fetchOrganizations();
                });
              }}
            >
              <label>Name</label>
              <input
                type={"text"}
                value={newProjectName}
                onChange={(event) => {
                  setNewProjectName(event.target.value);
                }}
                style={{ marginBottom: "8px" }}
              />
              <button type={"submit"}>Erstellen</button>
            </form>
          </div>
        </OrgProjects>

        <NewOrg extended={extended}>
          <h2
            onClick={() => {
              setExtended(!extended);
            }}
          >
            Neue Organization
          </h2>
          <Form onSubmit={createOrg}>
            <div className={"input-group"}>
              <label htmlFor={"orgName"}>Name</label>
              <input
                type={"text"}
                id={"orgName"}
                name={"orgName"}
                value={state.orgName}
                onChange={onChange}
              />
              <div className={"button-row"}>
                <Button className={"primary"} type={"submit"}>
                  Speichern
                </Button>
              </div>
            </div>
          </Form>
        </NewOrg>
      </Grid>
    </AppLayout>
  );
}
