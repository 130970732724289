import { SET_USERS } from "./action";

const initialState = [];

export const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS: {
      console.log(action);
      return action.users;
    }

    default:
      return state;
  }
};
