import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import colors from "../../constants/colors";
import borderRadius from "../../constants/borderRadius";
import fontSize from "../../constants/typeScale";

export const Search = styled.div`
  margin: ${spacing.spaceMd} 0;
  padding: ${spacing.space2xs};

  border-style: solid;
  border-width: 1px;
  border-color: ${colors.greys["250"]};
  border-radius: ${borderRadius.medium};
  //box-shadow: ${colors.greys["300"]} 2px 2px 6px;

  .search-field {
    display: flex;
    align-items: center;

    input {
      margin-top: 0;
      padding: ${spacing.space2xs} ${spacing.spaceXs};
      min-width: ${spacing.space6xl};

      border-style: none;
      font-size: ${fontSize.textMd};
    }
  }

  .search-results {
    padding: ${spacing.space2xs} 0 0 0;
    margin: 0;

    list-style: none;
    border-style: solid none none none;
    border-width: 1px;
    border-color: ${colors.greys["250"]};

    li + li {
      margin-top: ${spacing.space2xs};
    }

    li {
      button {
        padding: ${spacing.space4xs};
        display: flex;
        align-items: center;

        cursor: pointer;
        background-color: transparent;
        border-radius: ${borderRadius.medium};
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.greys["250"]};

        span {
          font-size: ${fontSize.textLg};
          margin: 0 //${spacing.spaceXs};
        }
      }
    }
  }
`;
