import { SET_ORGANIZATIONS } from "./action";

const initialState = {};

export const organizations = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS: {
      console.log(action);
      return action.organizations;
    }

    default:
      return state;
  }
};
