import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import colors from "../../constants/colors";
import borderRadius from "../../constants/borderRadius";
import fontSize from "../../constants/typeScale";

export const Form = styled.form`
  width: auto;
  display: flex;
  flex-direction: column;

  .input-group + .input-group {
    margin-top: ${spacing.spaceMd};
  }

  .input-group {
    display: flex;
    flex-direction: column;
  }

  .input-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    font-weight: bold;
  }

  input,
  select {
    margin-top: ${spacing.space2xs};
    padding: ${spacing.space2xs} ${spacing.spaceXs};
    min-width: ${spacing.space6xl};

    border-style: solid;
    border-width: 1px;
    border-color: ${colors.greys["300"]};
    border-radius: ${borderRadius.small};

    font-size: ${fontSize.textMd};
  }

  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.spaceMd};

    .message {
      color: red;
    }

    button {
      align-self: center;
    }
  }
`;
