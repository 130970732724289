import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import colors from "../../constants/colors";
import borderRadius from "../../constants/borderRadius";
import fontSize from "../../constants/typeScale";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.spaceXs};

  background-color: white;
  color: ${colors.primary["170"]};
  border-style: solid;
  border-width: 1px;
  border-radius: ${borderRadius.small};
  font-size: ${fontSize.textMd};
  letter-spacing: 0.12em;
  cursor: pointer;

  &.primary {
    background-color: ${colors.primary["130"]};
    color: white;
    border-style: none;

    box-shadow: ${colors.greys["700"]} 2px 2px 5px;
    
    &:hover, &:focus {
      background-color: ${colors.primary["170"]};
    }
  }

  // default
  &.secondary {
    background-color: white;
    color: ${colors.primary["170"]};
  }
`;
