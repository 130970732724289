import { combineReducers } from "redux";
import { auth } from "./auth/reducer";
import { organizations } from "./organizations/reducer";
import { users } from "./users/reducer";
import { appState } from "./appState/reducer";

// combined reducer
const combinedReducer = combineReducers({
  auth,
  users,
  organizations,
  appState,
});

// resets user data (e.g. after logout)
// has to be at top level
const RESET = "RESET";
export function reset() {
  return {
    type: RESET,
  };
}

/* ROOT REDUCER */
const rootReducer = (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
