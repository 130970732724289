/*
 * ACTION TYPES
 */

import axios from "axios";
import { routes } from "../../constants/routes";
import store from "../store";
import {
  isTokenExpired,
  refreshAndCall,
  refreshToken,
} from "../../services/auth";
import {ORGANIZATIONS_FETCHED, setOrganizationsFetched, setOrganizationsFetching} from "../appState/action";

export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";

/*
 * ACTION CREATORS
 */

export function setOrganizations(organizations) {
  console.log("!!", organizations);
  return {
    type: SET_ORGANIZATIONS,
    organizations: organizations,
  };
}

export function fetchOrganizations() {
  const jwt = store.getState().auth.user.token.accessToken;
  store.dispatch(setOrganizationsFetching())

  return function (dispatch) {
    return refreshAndCall(() => {
      axios
        .get(routes.api.organizations, {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        })
        .then((result) => {
          console.log(result);
          store.dispatch(setOrganizations(result.data));
          store.dispatch(setOrganizationsFetched());
        })
        .catch((err) => console.error(err));
    });
  };
}
