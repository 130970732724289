const apiBase = process.env.API_URL;

export const routes = {
  /* APP */
  app: "/",
  dashboard: "/dashboard",
  users: "/users",
  orgs: "/organizations",
  settings: "/settings",

  /* API */
  api: {
    base: apiBase,
    // auth
    login: `${apiBase}/v1/auth/login`,
    refreshToken: `${apiBase}/v1/auth/refresh-token`,
    register: `${apiBase}/v1/auth/register`,

    users: `${apiBase}/v1/users`,
    user: (userId) => `${apiBase}/v1/users/${userId}`,
    userAdmin: (userId) => `${apiBase}/v1/users/admin/${userId}`,

    organizations: `${apiBase}/v1/organizations`,
    organizationUsers: (orgId) => `${apiBase}/v1/organizations/${orgId}/users`,
    organizationUser: (orgId, userId) =>
      `${apiBase}/v1/organizations/${orgId}/users/${userId}`,

    organizationProjects: (orgId) =>
      `${apiBase}/v1/organizations/${orgId}/projects`,
  },

  pwa: {
    base: process.env.APP_URL,
  },

  admin: {
    base: process.env.ADMIN_URL,
  },
};
