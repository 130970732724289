import React from "react";
import { Router } from "@reach/router";
import Login from "../components/Login/Login";
import PrivateRoute from "../components/privateRoute";
import { routes } from "../constants/routes";
import Layout from "../components/Layout/Layout";
import SettingsPage from "../components/Settings";
import UsersPage from "../components/Users";
import OrganizationsPage from "../components/Organizations";
import DashboardPage from "../components/Dashboard";

const Index = ({ location }) => (
  <Layout>
    <Router>
      <PrivateRoute path={routes.dashboard} component={DashboardPage} />
      <PrivateRoute path={routes.users} component={UsersPage} />
      <PrivateRoute path={routes.orgs} component={OrganizationsPage} />
      <PrivateRoute path={routes.settings} component={SettingsPage} />
      <PrivateRoute path={"/"} component={DashboardPage} />
      <Login path="/login" />
    </Router>
  </Layout>
);

export default Index;
