import React from "react";
import { Sidebar } from "../Sidebar";
import GlobalStyle from "./GlobalStyle";
import ReduxWrapper from "../../redux/ReduxWrapper";

const Layout = ({ children }) => (
  <ReduxWrapper>
    <GlobalStyle />
    {children}
  </ReduxWrapper>
);

export default Layout;
