import React, { useEffect, useState } from "react";
import Login from "./Login/Login";
import { connect } from "react-redux";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    loading: !state.auth,
    Component: props.component,
    location: props.location,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteWrapper);

function PrivateRouteWrapper({ user, loading, Component, location, ...rest }) {
  const [authState, setAuthState] = useState({
    loading: true,
    loggedIn: false,
  });

  useEffect(() => {
    if (loading) {
      setAuthState({
        loading: true,
        loggedIn: false,
      });
    } else if (user && user.token) {
      setAuthState({
        loading: false,
        loggedIn: true,
      });
    } else {
      setAuthState({
        loading: false,
        loggedIn: false,
      });
    }
  }, [user, loading]);

  if (authState.loggedIn) {
    return <Component {...rest} />;
  } else if (authState.loading) {
    return <p>Loading...</p>;
  } else {
    return <Login />;
  }
}

export default PrivateRoute;
