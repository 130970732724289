import styled from "styled-components";
import React from "react";
import colors from "../../constants/colors";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";

const Tag = styled.span`
  padding: ${spacing.space3xs} ${spacing.space2xs};
  border-radius: ${borderRadius.small};

  &.up {
    color: white;
    background-color: green;
  }

  &.down {
    color: ${colors.status.green.dark};
    background-color: ${colors.status.green.light};
  }
  
  
`;

export function StatusTag({ children, status }) {
    return <Tag className={status}>{children}</Tag>;
}
