import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducer";
import { offline } from "@redux-offline/redux-offline/lib/index";
import defaultConfig from "@redux-offline/redux-offline/lib/defaults";
import thunk from "redux-thunk";
// import * as localforage from "localforage";
import axios from "axios";

// defaultConfig.persistOptions = { storage: localforage }; // store offline data in indexedDB

// use axios for effects
// const effect = (effect, _action) => axios(effect);
// const discard = (error, _action, _retries) => {
//     const { response } = error;
//     return response && 400 <= response.status && response.status < 500;
// };

const store = createStore(
  rootReducer,
  {},
  // composeWithDevTools(offline({ ...defaultConfig, effect, discard }))
  composeWithDevTools(applyMiddleware(thunk), offline({ ...defaultConfig }))
);

export default store;
