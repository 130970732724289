import React from "react";
import { connect } from "react-redux";
import { Users } from "./Users";
import {fetchUsers} from "../../redux/users/action";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
  };
};

const UsersPage = connect(mapStateToProps, mapDispatchToProps)(Users);

export default UsersPage;
