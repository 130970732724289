import React, { useEffect } from "react";
import AppLayout from "../Layout/AppLayout";
import { getToken, getUser, refreshToken } from "../../services/auth";
import { connect } from "react-redux";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    path: props.path,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const SettingsPage = connect(mapStateToProps, mapDispatchToProps)(Settings);

export default SettingsPage;

export function Settings({ path, user }) {
  useEffect(() => {
    refreshToken().then((r) => console.log(r));
  }, []);
  return (
    <AppLayout path={path}>
      <label>token</label>
      <p>{user.token.accessToken}</p>
    </AppLayout>
  );
}
