import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { Grid, NewUser, UserDetails, UserList } from "./styled";
import colors from "../../constants/colors";
import { NamedField } from "../Misc/namedField";
import { OrgDetails, OrgUsers } from "../Organizations/styled";
import { Button } from "../Misc/Button";
import { Form } from "../Misc/Form";
import { refreshAndCall } from "../../services/auth";
import axios from "axios";
import { routes } from "../../constants/routes";
import { UserMinusIcon } from "../../assets/icons/UserMinus";
import { TrashIcon } from "../../assets/icons/Trash";
import store from "../../redux/store";

export function Users({ path, user, fetchUsers, users }) {
  const [selectedUser, setSelectedUser] = useState(-1);
  // manages the state of the new user form at the bottom
  const [extended, setExtended] = useState(false);
  // state for the form to register a new user
  const initialState = {
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    role: "",
  };
  const [state, setState] = useState(initialState);
  const newUserFormRef = useRef();
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  // calls the register API
  function createUser(event) {
    event.preventDefault();

    const jwt = user.token.accessToken;

    // check if passwords match
    if (state.password !== state.confirmPassword) {
      setMessage("Die Passwörter stimmen nicht überein");
      return;
    }

    // check password against rules
    let rules = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
    );
    if (!rules.test(state.password)) {
      setMessage(
        "Das Passwort muss mindestens 8 Buchstaben, darunter mindestens je eine Zahl, einen Groß-" +
          "und einen Kleinbuchstaben haben."
      );
      return;
    }

    const { confirmPassword, ...newUserData } = state;

    refreshAndCall(() => {
      return axios.post(routes.api.register, newUserData, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
    }).then((r) => {
      setState(initialState);
      toggleForm();
      setMessage("");
      fetchUsers();
    });
  }

  function onChange(event) {
    event.persist();

    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function toggleForm() {
    setExtended(!extended);
    if (extended) {
      window.setTimeout(() => {
        newUserFormRef.current.style.display = extended ? "none" : "flex";
      }, 600);
    } else {
      newUserFormRef.current.style.display = extended ? "none" : "flex";
    }
  }

  function removeUser({ uid, userIndex }) {
    if (!window.confirm(`${users[userIndex].name} wirklich löschen?`)) {
      return;
    }
    const jwt = user.token.accessToken;
    refreshAndCall(() => {
      return axios.delete(routes.api.user(uid), {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
    })
      .then((result) => {
        // success
        fetchUsers();
      })
      .catch((err) => console.error(err));
  }

  function patchRole({ uid, userIndex, newRole }) {
    if (
      !window.confirm(
        `${users[userIndex].name}s Rolle wirklich zu ${newRole} ändern?`
      )
    ) {
      return;
    }
    const jwt = user.token.accessToken;
    refreshAndCall(() => {
      return axios.patch(
        routes.api.userAdmin(uid),
        {
          role: newRole,
        },
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
    })
      .then((result) => {
        // success
        fetchUsers();
      })
      .catch((err) => console.error(err));
  }

  return (
    <AppLayout path={path}>
      <Grid>
        <UserList>
          <h2>Benutzer</h2>
          {users ? (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Rolle</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {Object.keys(users).length
                  ? users.map((user, index) => {
                      return (
                        <tr
                          key={"user-td-" + index}
                          className={"tr-body"}
                          onClick={() => {
                            setSelectedUser(index);
                          }}
                        >
                          <td className={"name"}>{user.name}</td>
                          <td className={"mail"}>{user.email}</td>
                          <td
                            className={"role"}
                            onClick={() =>
                              patchRole({
                                uid: user.id,
                                userIndex: index,
                                newRole:
                                  user.role === "admin" ? "user" : "admin",
                              })
                            }
                          >
                            <div
                              className={"tag"}
                              style={{
                                backgroundColor:
                                  user.role === "admin"
                                    ? colors.status.red.light
                                    : colors.status.green.light,
                                color:
                                  user.role === "admin"
                                    ? colors.status.red.dark
                                    : colors.status.green.dark,
                              }}
                            >
                              {user.role}
                            </div>
                          </td>
                          <td
                            className={"remove"}
                            onClick={() =>
                              removeUser({ uid: user.id, userIndex: index })
                            }
                          >
                            <TrashIcon />
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          ) : (
            <p>Loading...</p>
          )}
        </UserList>

        <UserDetails show={selectedUser >= 0}>
          <h2>Details</h2>
          <NamedField className={"field"}>
            {" "}
            <label>Name</label>
            <span>{users[selectedUser]?.name}</span>
          </NamedField>
          <NamedField className={"field"}>
            {" "}
            <label htmlFor={"email-field"}>Email</label>
            <span id={"email-field"}>{users[selectedUser]?.email}</span>
          </NamedField>
          <NamedField className={"field"}>
            {" "}
            <label htmlFor={"registered-at-field"}>Registriert am</label>
            <span id={"registered-at-field"}>
              {new Date(users[selectedUser]?.createdAt).toLocaleString()}
            </span>
          </NamedField>
          <NamedField className={"field"}>
            {" "}
            <label htmlFor={"organizations-field"}>Organizations</label>
            <ul id={"organizations-field"}>
              {users[selectedUser]?.organizations.map((org, index) => {
                // TODO this willh have to be requestes from api
                const storeOrg = store
                  .getState()
                  .organizations.find(
                    (organization) => organization.id === org
                  );
                return <li key={"org-details-" + index}>{storeOrg.name}</li>;
              })}
            </ul>
          </NamedField>{" "}
        </UserDetails>

        <NewUser extended={extended}>
          <h2 onClick={toggleForm}>Neuen Benutzer anlegen</h2>
          <Form
            onSubmit={createUser}
            ref={newUserFormRef}
            style={{ display: "none" }}
          >
            <div className={"input-group"}>
              <label htmlFor={"name"}>Name</label>
              <input
                type={"text"}
                id={"name"}
                name={"name"}
                value={state.name}
                onChange={onChange}
              />
            </div>
            <div className={"input-group"}>
              <label htmlFor={"email"}>E-Mail</label>
              <input
                type={"email"}
                id={"email"}
                name={"email"}
                value={state.email}
                onChange={onChange}
              />
            </div>
            <div className={"input-group"}>
              <label htmlFor={"password"}>Passwort</label>
              <input
                type={"password"}
                id={"password"}
                name={"password"}
                value={state.password}
                onChange={onChange}
              />
            </div>
            <div className={"input-group"}>
              <label htmlFor={"confirmPassword"}>Passwort Bestätigen</label>
              <input
                type={"password"}
                id={"confirmPassword"}
                name={"confirmPassword"}
                value={state.confirmPassword}
                onChange={onChange}
              />
            </div>
            <div className={"input-group"}>
              <select value={state.role} onChange={onChange} name={"role"}>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div className={"button-row"}>
              <p className={"message"}>{message}</p>
              <Button className={"primary"} type={"submit"}>
                Erstellen
              </Button>
            </div>
          </Form>
        </NewUser>
      </Grid>
    </AppLayout>
  );
}
