import styled from "styled-components";
import fontSize from "../../constants/typeScale";

export const NamedField = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-weight: bold;
    font-size: ${fontSize.textSm};
  }
`;
