import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";

export const AppSection = styled.section`
  margin: 0;
  padding: ${spacing.spaceMd};

  border-radius: ${borderRadius.medium};
  background-color: white;
  overflow-x: auto;

  box-shadow: 0px 0px 16px #ddd;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 16px hsl(0, 0%, 80%);
  }
`;
