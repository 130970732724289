import React from "react";
import { useEffect, useState } from "react";
import SignInForm from "./SignInForm";
import handleAuthError from "./handleAuthError";
import { AuthBoxText } from "./AuthBoxText";
import { handleLogin } from "../../services/auth";

// TODO signInWithTwitter
// TODO add texts as parameter so they can be customized
// TODO does handleAuthError need to be customized?
/**
 *
 * @param requestLogin - a function that is given email and password. It can call
 *        handleAuthError.
 * @returns {JSX.Element}
 * @constructor
 */
export default function Login() {
  require("./styles.css");
  // error message, displayed below the password field
  const [message, setMessage] = useState("");
  // Auth box shakes when an error occurs
  const [shaking, setShaking] = useState(false);
  // this holds the form fields
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  // copy of mode, initial value is the given paramter. User can switch it.

  // text strings for button texts etc
  const text = AuthBoxText;

  // stops shaking after 1000ms
  useEffect(() => {
    window.setTimeout(() => {
      setShaking(false);
    }, 1000);
  }, [shaking]);

  return (
    <div className={"auth-box-wrapper"}>
      <div
        className={"auth-box-root"}
        id={"signInBox"}
        style={{
          animation: shaking ? "shake 1s linear infinite" : "none",
        }}
      >
        <div className={"auth-box-message"}>
          <span>{text.authText}</span>
        </div>
        <SignInForm
          requestLogin={handleLogin}
          message={message}
          text={text}
          state={state}
          setState={setState}
          handleAuthError={(error) =>
            handleAuthError(error, setMessage, setShaking)
          }
        />
      </div>
    </div>
  );
}

