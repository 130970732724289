import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";
import colors from "../../constants/colors";
import fontSize from "../../constants/typeScale";
import { AppSection } from "../Misc/AppSection";

export const Grid = styled.div`
  //height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${spacing.spaceMd};
  grid-row-gap: ${spacing.spaceMd};

  //height: 100%;
  //width: 100%;

  h2 {
    margin-top: 0;
  }
`;

export const Stats = styled(AppSection)`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;

  height: auto;
`;

export const Status = styled(AppSection)`
  grid-area: 1 / 2 / 2 / 3;

  display: flex;
  flex-direction: column;

  div + div {
    margin-top: ${spacing.spaceSm};
  }

  div {
    display: flex;
    label {
      font-weight: bold;
      margin-right: ${spacing.spaceXs};
    }
  }

  .route {
    margin-left: ${spacing.spaceMd};
  }
`;
