import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";
import colors from "../../constants/colors";
import fontSize from "../../constants/typeScale";
import { AppSection } from "../Misc/AppSection";
import Index from "../../pages";

export const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: ${spacing.spaceMd};

  //height: 100%;
  //width: 100%;

  h2 {
    margin-top: 0;
  }
`;

export const UserList = styled(AppSection)`
  grid-area: 1 / 1 / 2 / 2;

  table {
    margin-left: -${spacing.space2xs};
    th {
      text-align: left;

      padding: ${spacing.spaceXs} ${spacing.spaceMd} ${spacing.spaceXs}
        ${spacing.space2xs};
    }

    td {
      padding: ${spacing.spaceSm} ${spacing.spaceMd} ${spacing.spaceSm}
        ${spacing.space2xs};
    }

    .tr-body {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${colors.primary["30"]};
      }
    }

    .name {
      padding-right: ${spacing.spaceLg};
    }

    .mail {
      padding-right: ${spacing.space2xl};
    }

    .role {
      padding-right: ${spacing.spaceXl};
    }

    .tag {
      padding: ${spacing.space3xs} ${spacing.space2xs};
      border-radius: ${borderRadius.large};

      text-align: center;
    }
  }
`;

export const UserDetails = styled(AppSection)`
  grid-area: 2 / 1 / 3 / 2;

  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;

  .field + .field {
    margin-top: ${spacing.spaceMd};
  }

  #organizations-field {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

export const NewUser = styled(AppSection)`
  grid-area: 3 / 1 / 4 / 3;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  height: ${(props) => (props.extended ? "60vh" : "20px")};
  align-self: flex-end;

  //overflow-y: hidden;

  transition: all 600ms ease-in-out;

  h2 {
    cursor: pointer;
  }

  select {
    padding: ${spacing.spaceXs};
    font-size: ${fontSize.textMd};

    margin: 0 0 6px -8px;
    width: calc(100% + 16px);
  }
`;
