import React from "react";
import { connect } from "react-redux";
import { Dashboard, Users } from "./Dashboard";
import { fetchUsers } from "../../redux/users/action";
import store from "../../redux/store";
import { fetchOrganizations } from "../../redux/organizations/action";

const mapStateToProps = (state, props) => {
  return {
    users: state.users,
    organizations: state.organizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    fetchUsers: () => dispatch(fetchUsers()),
  };
};

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardPage;
