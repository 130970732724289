import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";
import colors from "../../constants/colors";
import fontSize from "../../constants/typeScale";
import { AppSection } from "../Misc/AppSection";
import dimensions from "../../constants/dimensions";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: ${spacing.spaceMd};
  grid-row-gap: ${spacing.spaceMd};

  height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 100%;

  h2 {
    margin-top: 0;
  }
`;

export const LoaderStyle = styled.div`
  position: relative;
  display: ${(props) => (props.show ? "flex" : "none")};
  height: 100%;
  width: 100%;

  background-color: white;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const OrgList = styled(AppSection)`
  grid-area: 1 / 1 / 2 / 2;

  box-sizing: border-box;

  table {
    margin-left: -${spacing.space2xs};
    th {
      text-align: left;

      padding: ${spacing.spaceXs} ${spacing.spaceMd} ${spacing.spaceXs}
        ${spacing.space2xs};
    }

    td {
      padding: ${spacing.spaceSm} ${spacing.spaceMd} ${spacing.spaceSm}
        ${spacing.space2xs};
    }

    .active {
      background-color: ${colors.primary["30"]};
    }

    .tr-body {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${colors.primary["30"]};
      }
    }

    .name {
      padding-right: ${spacing.spaceLg};
    }

    .mail {
      padding-right: ${spacing.space2xl};
    }

    .role {
      padding-right: ${spacing.spaceXl};
    }
  }
`;

export const OrgUsers = styled(AppSection)`
  grid-area: 1 / 2 / 2 / 3;
  display: ${(props) => (props.show ? "block" : "none")};
  flex-direction: column;

  width: 100%;
  max-width: 100%;
  min-width: 100%;

  box-sizing: border-box;

  div {
    width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;

    list-style: none;
  }

  li + li {
    margin-top: ${spacing.spaceXs};
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .name {
    display: flex;
    flex-direction: column;
    padding-right: ${spacing.spaceMd};

    .name-name {
      font-weight: 500;
      //font-size: ${fontSize.textLg};
    }

    .name-email {
      color: ${colors.greys["400"]};
      font-weight: bold;
    }
  }

  .role {
    display: flex;
    align-items: center;
  }

  .delete {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      padding: 0;

      background-color: transparent;
      color: ${colors.greys["700"]};
      border-style: none;
      cursor: pointer;

      &:hover,
      &:focus {
        color: red;
      }
    }
  }

  select {
    padding: ${spacing.spaceXs};
    margin: 0 0 6px 0;
    width: 100%;

    //border-color: ${colors.greys["250"]};
    font-size: ${fontSize.textMd};
  }

  .add-user {
    flex-direction: column;
    margin: ${spacing.spaceXs} 0;
    padding: ${spacing.space2xs};

    border-style: solid;
    border-width: 1px;
    border-color: ${colors.greys["250"]};
    border-radius: ${borderRadius.medium};

    div + div {
      margin-top: ${spacing.spaceXs};
    }
  }
`;

export const OrgDetails = styled(AppSection)`
  grid-area: 2 / 1 / 3 / 3;

  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;

  .field + .field {
    margin-top: ${spacing.spaceMd};
  }

  .field {
    display: flex;
    flex-direction: column;
    label {
      font-weight: bold;
      font-size: ${fontSize.textSm};
    }

    #organizations-field {
      margin: 0;
      padding: 0;

      list-style: none;
    }
  }
`;

export const OrgProjects = styled(AppSection)`
  grid-area: 3 / 1 / 4 / 3;

  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;

  .project-list {
    display: flex;

    .project + .project {
      margin-left: ${spacing.space2xs};
    }

    .project {
      display: flex;
      flex-direction: column;
      min-width: ${spacing.space5xl};
      padding: ${spacing.spaceMd};

      border-style: solid;
      border-width: 1px;
      border-radius: ${borderRadius.small};

      h3 {
        margin: 0;
      }
    }

    .add-project {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
      }
    }
  }
`;

export const NewOrg = styled(AppSection)`
  grid-area: 4 / 1 / 5 / 3;

  display: flex;
  flex-direction: column;
  height: ${(props) => (props.extended ? spacing.space5xl : "20px")};
  align-self: flex-end;

  overflow-y: hidden;

  transition: all 600ms ease-in-out;

  h2 {
    cursor: pointer;
  }
`;
