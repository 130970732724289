import React from "react";
import { Sidebar } from "../Sidebar";
import GlobalStyle from "./GlobalStyle";
import styled from "styled-components";
import colors from "../../constants/colors";
import { spacing } from "../../constants/spacing";
import ReduxWrapper from "../../redux/ReduxWrapper";

const AppPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  background-color: ${colors.dashboard.background};

  .content {
    margin: ${spacing.spaceMd} ${spacing.spaceMd} ${spacing.spaceMd} 0;
    width: calc(100% - ${spacing.space5xl} - 3 * ${spacing.spaceMd});
    height: calc(100% -${spacing.spaceMd});
  }
`;

function AppLayout({ children, path }) {
  return (
    <ReduxWrapper>
      <GlobalStyle />

      <AppPage>
        <Sidebar path={path} />
        <section className={"content"}>{children}</section>
      </AppPage>
    </ReduxWrapper>
  );
}

export default AppLayout;
