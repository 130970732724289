import styled from "styled-components";
import React from "react";
import colors from "../../constants/colors";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";

const Tag = styled.span`
  padding: ${spacing.space3xs} ${spacing.space2xs};
  border-radius: ${borderRadius.large};

  &.admin {
    color: white;
    background-color: black;
  }

  &.user {
    color: ${colors.status.green.dark};
    background-color: ${colors.status.green.light};
  }
`;

export function RoleTag({ role }) {
  return <Tag className={role}>{role}</Tag>;
}
