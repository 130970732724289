import {
  ORGANIZATIONS_FETCHED,
  ORGANIZATIONS_FETCHIING,
  USERS_FETCHED,
  USERS_FETCHING,
} from "./action";

const initialState = {
  organizationsFetching: true,
  usersFetching: true,
};

export const appState = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS_FETCHED: {
      return Object.assign({}, state, {
        organizationsFetching: false,
      });
    }

    case USERS_FETCHED: {
      return Object.assign({}, state, {
        usersFetching: false,
      });
    }

    case ORGANIZATIONS_FETCHIING: {
      return Object.assign({}, state, {
        organizationsFetching: true,
      });
    }

    case USERS_FETCHING: {
      return Object.assign({}, state, {
        usersFetching: true,
      });
    }

    default:
      return state;
  }
};
