import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";
import fontSize from "../../constants/typeScale";

const GlobalStyle = createGlobalStyle`

  
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", Helvetica, sans-serif;
  }
  
  button, label {
    font-family: "Montserrat", Helvetica, sans-serif;
    font-size: ${fontSize.textMd};
  }
`;

export default GlobalStyle;
