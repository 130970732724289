import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import borderRadius from "../../constants/borderRadius";
import colors from "../../constants/colors";
import { Link } from "gatsby";
import fontSize from "../../constants/typeScale";
import { AppSection } from "../Misc/AppSection";

export const NavList = styled(AppSection)`
  width: ${spacing.space5xl};
  margin: ${spacing.spaceMd};
  padding: ${spacing.spaceMd};

  border-radius: ${borderRadius.medium};
  background-color: white;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li + li {
    margin-top: ${spacing.spaceMd};
  }

  li {
  }

  .spacer {
    height: 100px;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;

  color: ${colors.greys["800"]};
  text-decoration: none;

  svg {
    width: ${spacing.spaceMd};
    height: ${spacing.spaceMd};

    margin-right: ${spacing.space2xs};
  }

  &.active {
    color: ${colors.primary["170"]};

    svg {
      stroke: ${colors.primary["170"]} !important;
    }
  }
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;

  color: ${colors.greys["800"]};
  text-decoration: none;
  background-color: transparent;
  border-style: none;
  font-size: ${fontSize.textMd};
  cursor: pointer;

  svg {
    width: ${spacing.spaceMd};
    height: ${spacing.spaceMd};

    margin-right: ${spacing.space2xs};
  }

  &.active {
    color: ${colors.primary["170"]};

    svg {
      stroke: ${colors.primary["170"]} !important;
    }
  }
`;
