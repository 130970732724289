/*
 * ACTION TYPES
 */

import axios from "axios";
import { routes } from "../../constants/routes";
import store from "../store";
import {
  isTokenExpired,
  refreshAndCall,
  refreshToken,
} from "../../services/auth";
import { setOrganizations } from "../organizations/action";
export const ORGANIZATIONS_FETCHIING = "ORGANIZATIONS_FETCHING";
export const USERS_FETCHING = "USERS_FETCHING";
export const ORGANIZATIONS_FETCHED = "ORGANIZATIONS_FETCHED";
export const USERS_FETCHED = "USERS_FETCHED";

/*
 * ACTION CREATORS
 */

export function setOrganizationsFetching() {
  return {
    type: ORGANIZATIONS_FETCHIING,
  };
}

export function setUsersFetching() {
  return {
    type: USERS_FETCHING,
  };
}

export function setOrganizationsFetched() {
  return {
    type: ORGANIZATIONS_FETCHED,
  };
}

export function setUsersFetched() {
  return {
    type: USERS_FETCHED,
  };
}
