/*
 * ACTION TYPES
 */

import axios from "axios";
import { routes } from "../../constants/routes";
import store from "../store";
import {
  isTokenExpired,
  refreshAndCall,
  refreshToken,
} from "../../services/auth";
import {
  setOrganizationsFetching,
  setUsersFetched,
  setUsersFetching,
} from "../appState/action";

export const FETCH_USERS = "FETCH_USERS";
export const SET_USERS = "SET_USERS";

/*
 * ACTION CREATORS
 */

export function setUsers(users) {
  return {
    type: SET_USERS,
    users: users,
  };
}

export function fetchUsers() {
  const jwt = store.getState().auth.user.token.accessToken;
  store.dispatch(setUsersFetching());

  return function (dispatch) {
    return refreshAndCall(() => {
      axios
        .get(routes.api.users, {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        })
        .then((result) => {
          store.dispatch(setUsers(result.data));
          store.dispatch(setUsersFetched());
        })
        .catch((err) => console.error(err));
    });
  };
}
