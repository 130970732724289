import React, { useState } from "react";
import { NavButton, NavLink, NavList } from "./styled";

import { routes } from "../../constants/routes";
import { DashboardIcon } from "../../assets/icons/DashboardIcon";
import { UserIcon } from "../../assets/icons/UserIcon";
import { OrgIcon } from "../../assets/icons/OrgIcon";
import { LogoutIcon } from "../../assets/icons/LogoutIcon";
import { SettingsIcon } from "../../assets/icons/SettingsIcon";
import { handleLogout } from "../../services/auth";

export function Sidebar({ path }) {
  return (
    <NavList>
      <ul>
        <li>
          <NavLink
            to={routes.dashboard}
            className={path === routes.dashboard ? "active" : "none"}
          >
            <DashboardIcon />
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.users}
            className={path === routes.users ? "active" : "none"}
          >
            <UserIcon />
            Benutzer
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.orgs}
            className={path === routes.orgs ? "active" : "none"}
          >
            <OrgIcon />
            Organizations
          </NavLink>
        </li>
        <div className={"spacer"} />
        <li>
          <NavLink
            to={routes.settings}
            className={path === routes.settings ? "active" : "none"}
          >
            <SettingsIcon />
            Einstellungen
          </NavLink>
        </li>
        <li>
          <NavButton onClick={handleLogout}>
            <LogoutIcon />
            Log Out
          </NavButton>
        </li>
      </ul>
    </NavList>
  );
}
