import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { Grid, Stats, Status } from "./styled";
import { routes } from "../../constants/routes";
import { StatusTag } from "../Misc/StatusTag";
import { StyledLink } from "../Misc/StyledLink";

export function Dashboard({
  path,
  fetchOrganizations,
  fetchUsers,
  users,
  organizations,
}) {
  const [status, setStatus] = useState({
    api: false,
    admin: false,
    pwa: false,
  });
  useEffect(() => {
    fetchOrganizations();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetch(routes.api.base).then((r) =>
      setStatus((prevState) => ({ ...prevState, ["api"]: true }))
    );
    fetch(routes.app).then((r) =>
      setStatus((prevState) => ({ ...prevState, ["admin"]: true }))
    );
    fetch(routes.pwa.base).then((r) =>
      setStatus((prevState) => ({ ...prevState, ["pwa"]: true }))
    );
  }, []);

  return (
    <AppLayout path={path}>
      <Grid>
        <Stats>
          <h2>Stats</h2>
          <span>{users.length} Benutzer</span>
          <span>{organizations.length} Organizations</span>
        </Stats>

        <Status>
          <h2>Status</h2>
          {Object.keys(status).map((key, index) => {
            const statusText = status[key] ? "up" : "down";
            const route = routes[key].base;
            return (
              <div key={"status-" + index}>
                <label>{key}</label>
                <span>
                  {<StatusTag status={statusText}>{statusText}</StatusTag>}
                </span>
                <StyledLink to={route} className={"route"}>
                  {route}
                </StyledLink>
              </div>
            );
          })}
        </Status>
      </Grid>
    </AppLayout>
  );
}
