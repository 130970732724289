import { Provider } from "react-redux";
import store from "../redux/store";
import React from "react";

/*
 *  Wraps the redux provider around children
 */
export default function ReduxWrapper({ children }) {
  return <Provider store={store}>{children}</Provider>;
}
