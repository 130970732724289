import React from "react";
import { connect } from "react-redux";
import { Organizations, Users } from "./Organizations";
import { fetchOrganizations } from "../../redux/organizations/action";
import { fetchUsers } from "../../redux/users/action";
import store from "../../redux/store";
import { setOrganizationsFetching } from "../../redux/appState/action";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    users: state.users,
    organizations: state.organizations,
    organizationsFetching: state.appState.organizationsFetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    fetchUsers: () => dispatch(fetchUsers()),
    getUser: (id) => {
      console.log(id);
      const users = store.getState().users;
      console.log(users);
      let userFound = false;
      users.forEach((user) => {
        if (user.id === id) userFound = user;
      });
      console.log(userFound);
      return userFound;
    },
    setOrganizationsFetching: () => {
      dispatch(setOrganizationsFetching());
    },
  };
};

const OrganizationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);

export default OrganizationsPage;
