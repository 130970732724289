/*
 * ACTION TYPES
 */

export const SET_USER = "SET_USER";

/*
 * ACTION CREATORS
 */

export function setUser(user) {
  return {
    type: SET_USER,
    user: user,
  };
}
